import React, { useState, useEffect } from "react";
import * as app from "../../services/AppService";
import { useSelector, useDispatch } from "react-redux";
import { getData } from "../../store/data";
import PropTypes from "prop-types";
import Select from "../Select";

function UserBranches(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getData("UserBranches", {}, (UserBranches) => {
        if (
          UserBranches &&
          UserBranches.length > 0 &&
          props.OnLoad &&
          typeof props.OnLoad == "function"
        )
          props.OnLoad(UserBranches);
      })
    );
  }, []);
  const selector = useSelector((store) => store.data.UserBranches);
  const UserBranches = props.WithAllBranches
    ? [{ Value: app.translate("AllBranchs"), Key: 0 }, ...selector]
    : selector;
    return (
        <Select Name="UserBranches" Label={props.Label? props.Label : "Branch"} Options={UserBranches} {...props}/>
  );
}

UserBranches.propTypes = {
  WithAllBranches: PropTypes.bool,
  Label:PropTypes.string,
};
UserBranches.defaultProps = {
  WithAllBranches: false,
};
export default UserBranches;
