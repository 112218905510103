import React from "react"
export default function Spinner(props) {
    return (props.Loading &&
        <div>
            {/*<div className="overlay show"></div>*/}
            {/*<div className="spanner show">*/}
            {/*    <div className="loader"></div> */}
            {/*</div>*/}

            <div className="d-flex justify-content-center align-items-center position-fixed bg-dark w-100 h-100 z-10 bg-opacity-50 " >
                <div className="">
                    <div className="d-flex justify-content-center align-items-center spinner-border text-light fw-bold" role="status" style={{ width: "7rem", height: "7rem" }}>
                        <div className="d-flex justify-content-center align-items-center spinner-border text-primary text-secoundary fw-bold" role="status" style={{ width: "6rem", height: "6rem" }}>
                            <div className="spinner-border text-light text-secoundary fw-bold" role="status" style={{ width: "5rem", height: "5rem" }}></div>
                        </div>
                    </div>
                </div>
                
            </div>
            
        </div>
    );
}
