import { Modal } from "react-bootstrap";
import Button from "../Button";
import * as app from "../../services/AppService.js"
import React, { useState } from "react" 
import store from "../../store"
import { hideMsgDialog } from "../../store/msgDialog"
import Input from "../Input"
import { useEffect } from "react";

function MsgDialog(props) {

    const [state, setState] = useState("")

    function Close() {
        store.dispatch(hideMsgDialog())
        if (props.OnClose && typeof (props.OnClose) == "function")
            props.OnClose();
    }
    function Ok() {
        store.dispatch(hideMsgDialog())
        if (props.Ok && typeof (props.Ok) == "function")
            props.Ok(state)
        setState("")
    }

    useEffect(() => {
        setState(props.DefValue)
    }, [props.DefValue])

    let Title = props.Title;
    let Body = props.Body;
    let CloseLabel = props.CloseLabel ? props.CloseLabel : Body == "MsgCompleteProcess" ?"Ok": "Close";
    let Error = false;
    if (!props.Title)
        Title = Body == "MsgUpdateComplete" || Body == "MsgCompleteProcess" ? "success" : "Error";

    if (!Body) {
        Body = app.translate("Error_Operation");
    }
    if (Title == "Error" || Title == "Validation") Error = true;
    if (["MsgUpdateComplete", "MsgCompleteProcess"].includes(Body))
        Body = app.translate(Body);
    return (
        <Modal data-bs-theme={app.getCookie("colorMode", window.matchMedia('(prefers-color-scheme: dark)').matches ? "dark" : "light")} show={props.Show} onHide={Close} style={{ direction: app.translate("Lang") == "ar" ? "rtl" : "ltr" }}>
            <Modal.Header onClick={Close} className={`py-2 border-bottom text-primary-emphasis ${Error ? "bg-danger" : Title == "Warning" ? "bg-warning" : "bg-body-tertiary"}`}>
                <Modal.Title>{app.translate(Title)}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-body-tertiary text-dark-emphasis">
                {(!props.ConfirmMsg&&Body)}
                {(props.ConfirmMsg && <>
                    <span>{props.ConfirmMsg}</span>
                    <Input Label={props.Label} Model={state}
                OnChange={state => setState(state)} />
        </>)}
            </Modal.Body>
            <Modal.Footer className="bg-body-tertiary border-top py-2">
                <div className=" me-auto">
                    {props.ExtraButtons && props.ExtraButtons.map((btn, i) => {
                        return <Button key={i} Class='btn btn-primary mx-1' Clicked={btn.Clicked} Label={btn.Label} Icon={btn.Icon} />
                    })}
                </div>
                <Button Class={CloseLabel == "Ok" ?"btn btn-success": "btn-danger"} Clicked={Close} Label={CloseLabel} Icon="close-1" />
                {props.Ok && <Button Class={`btn btn-${Title == "Warning" ? "warning" : "success"}`} Clicked={Ok} Label="Ok" Icon="done-1" />}
                
            </Modal.Footer>
        </Modal>
    );
}
export default MsgDialog;
 