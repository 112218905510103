import React from "react";
import PropTypes from "prop-types";
import Checkbox from "./Checkbox";
import * as app from "../services/AppService";

function DatePicker(props) {
    //https://reactdatepicker.com/#example-custom-date-format
    // اذا بدنا نثبت الفورمات
    function getDateString(date) {
        try {
            return (
                (date.getMonth() < 9 ? "0" : "") +
                (date.getMonth() + 1) +
                "-" +
                date.getFullYear() +
                "-" +
                (date.getDate() < 10 ? "0" : "") +
                date.getDate()
            );
        } catch (e) {
            return date;
        }
    }

    function handler(event) {
        const { value } = event.target;
        if (props.OnChange && typeof props.OnChange == "function")
            props.OnChange(value);
    }

    function blurhandler(event) {
        const { value } = event.target;
        if (value == '')
            props.OnChange(app.today());

    }
    var datePicker = <input
        id={props.ID ? props.ID : "date" + props.Label}
        type="date"
        value={getDateString(props.Model)}
        className={`form-control form-control-sm rounded ${props.Required && !props.Model ? " is-invalid  border-1" : props.IsTable ? " border-1 text-center bg-body-tertiary " + props.TableClass : "border-0 " + props.Class}`}
        disabled={props.Disabled || props.CheckboxModel === false}
        onChange={handler}
        onBlur={blurhandler}
        placeholder={app.translate(props.Placeholder)}
        max="3000-12-31"
        required={props.Required}
    />;

    return (
        props.IsTable ? datePicker:
        <div className="mt-1">
             <label className="fw-semibold text-primary-emphasis">{app.translate(props.Label)}</label>
            <div className="input-group border rounded">
                    {props.CheckboxModel != undefined && <Checkbox Name="IsFrom" checkClass="mt-1 ms-2" className="text-center" Model={props.CheckboxModel} OnChange={props.CheckboxChanged} />}
                {datePicker}
            </div>
        </div>
    );
}

DatePicker.propTypes = {
    Label: PropTypes.string,
    Class: PropTypes.string,
    Disabled: PropTypes.bool,
    Required: PropTypes.bool,
    Placeholder: PropTypes.string,
    OnChange: PropTypes.func,
    CheckboxModel: PropTypes.bool,
    IsTable: PropTypes.bool,
    CheckboxChanged: PropTypes.func
};

DatePicker.defaultProps = {
    Label: "Date",
    Model: new Date(),
    Placeholder: "",
    Class: " ",
    Disabled: false,
    Required: false,
    IsTable: false
}

export default DatePicker;