import React, { useEffect, useState } from 'react'
import * as http from "../services/http"
import * as app from "../services/AppService"
import * as card from "../services/CardService"
import Autocomplete from "./Autocomplete"

function CardSearch(props) {

    const [searchData, setSearchData] = useState({
        Model: props.Model.ID,
        autoOptions: [],
        DefaultString: "",
    })

    useEffect(() => {
        setSearchData(old => ({
            ...old,
            Model: props.Model.ID,
            DefaultString: props.Model.FirstName
        }));
        if (props.Model.ID == 0 && props.Model.FirstName == "")
            setSearchData(old => ({ ...old, autoOptions: [] }));
    }, [props.Model.FirstName, props.Model.ID])

    function getModel(Name) {
        return { ...card.GetDefault(Name), Card_Jop: props.Model.Card_Jop }; 
    }

    function getCards(Name) {
        if (Name.length < 3) return;
        http.get("/Service/SearchCards", { Name, Filter: props.Filter}, res => {
            var Cards = res.AdditionalData;
            setSearchData(old => ({
                ...old, DefaultString: "",
                autoOptions: props.AfterSearch || props.WithOutSearch ? Cards : [{ Value: app.translate("New") + "(" + Name + ")", Key: 0, Name }, ...Cards]
            }));
            if (Cards.length === 0) {
                if (props.WithOutSearch) { props.OnChange && props.OnChange(0); return; }
                if (props.searchOnBlur) { props.OnChange(getModel(Name)); return; }
                if (props.AfterSearch && typeof (props.AfterSearch) == "function")
                    props.AfterSearch(getModel(Name));
                else
                    props.OnChange(getModel(Name));
            } else {
                setSearchData(old => ({ ...old, DefaultString: "" }));
                if (props.searchOnBlur)
                    document.getElementById(props.ID ? props.ID : "auto" + props.Label).focus();
            }
            //if (Cards.length == 1)
            //    getCardByID(Cards[0].Key);
        })
    }

    function getCardByID(CardID, Obj) {
        if (props.WithOutSearch) { props.OnChange && props.OnChange(CardID); return; }
        if (+CardID === 0) {
            const DefaultString = Obj.Name ? Obj.Name : Obj.Value;
            props.OnChange(getModel(DefaultString));
            return;
        }
        if (props.Model.ID == CardID) return;//, WithImage: false
        http.get("/Service/GetCardByID", { CardID, WithImage: +props.Filter == 2 }, res => {
            if (res.Success)
                props.OnChange(res.AdditionalData);
            if (!res.CarryOnData)
                app.disblayError(res, true)
        })
    }

    function onBlur(item) {
        if (props.searchOnBlur && (!props.Model.FirstName || props.Model.FirstName.trim() != item.Value.trim())) getCards(item.Value);
        if (item.Value.trim() == "" && props.Model.ID>0) props.OnChange(getModel());
    }

    function enterClicked(name) {
        if (props.searchOnBlur) {
            (document.getElementById(props.ID ? props.ID : "auto" + props.Label)).blur()
        }
        else
            getCards(name)
    }

    return (
        <Autocomplete
            Options={searchData.autoOptions}
            DefaultValue={searchData.DefaultString}
            Model={searchData.Model}
            Label={props.Label}
            Disabled={props.Disabled}
            Required={props.Required}
            OnChange={getCardByID}
            OnEnterClicked={enterClicked}
            OnFocus={props.OnFocus}
            Icon={props.Icon}
            IconClicked={props.IconClicked}
            OnBlur={onBlur}
        />
    );
}

CardSearch.defaultProps = {
    Class: "",
    Filter: 2,
    Disabled: false,
    Required: false
}

export default CardSearch;