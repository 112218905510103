import React, { useEffect } from "react";
import * as app from "../services/AppService";
import PropTypes from "prop-types";
import Dialog from "./PublicDialogs/Dialog";

export default function ImageUpload(props) {

    const [state, setState] = React.useState({
        show: false,
        DialogImage: '',
        ImageSrc: "#",
        file:"",
        showImgCard: false
    });

    useEffect(() => {
        let ImageSrc = !props.Model || props.Model.length == 0 ? "#" : props.Model.includes("data:") ? props.Model :
            (props.Model.startsWith("JVBERi0xLj") ? "data:application/pdf;base64," : "data:image/jpeg;base64,") + props.Model;
        setState(old => ({
            ...old,
            showImgCard: props.Model && props.Model.length > 0 ? true : false,
            ImageSrc,
            file: ImageSrc
        }));
    }, [props.Model]);

    function readURL(event) {
        var input = event.target;
        if (input.files && input.files[0]) {
            var reader = new FileReader();
            reader.onload = function (e) {
                var ImageSrc = e.target.result; 
                if (props.OnChange && typeof (props.OnChange) == "function")
                    props.OnChange(e.target.result.split("base64,")[1], input.files[0].name)

                setState(old => ({ ...old, showImgCard: true, ImageSrc, file: ImageSrc }));
            };
            reader.readAsDataURL(input.files[0]);
            if (props.DisplayAsButton) {
                const fileChosen = document.getElementById("fileChosen");
                fileChosen.textContent = input.files[0].name;
            }
        } else {
            removeUpload();
        }
    }

    function openDialog() {
        if (state.file.split("base64,")[0].includes("image"))
            setState(old => ({
                ...old, show: true,
                DialogImage: (<img className="card-img-top" src={old.ImageSrc} alt="" id={"file-upload-image" + props.Title} />)
            }))
        else {
            var fileWindow = window.open("");
            fileWindow.document.write("<iframe width='100%' height='100%' src='" + encodeURI(state.file) + "'></iframe>");
        }
    }

    function removeUpload() {
        setState(old => ({ ...old, ImageSrc: "#", showImgCard: false }));
        if (props.OnChange && typeof (props.OnChange) == "function")
            props.OnChange("")
    }

    if (props.DisplayAsButton) {
        return (
            <div className="row">
                <div className={"col-12 d-flex align-items-center gap-2 " + props.Class}>

                    <label className="w-auto form-control" htmlFor={"img-upload-input" + props.Title}>
                        {app.translate(props.Title)}
                        <span className="iconssc-cloud ms-2"></span>
                    </label>
                    <input className="d-none" id={"img-upload-input" + props.Title} type='file' onChange={readURL} accept="image/*"   />
                    <span id="fileChosen" className="col-auto"></span>

                </div>
            </div>
        );
    } else {
        return (
            <div className="row">
                <div>
                    <h6 className={`text-primary-emphasis ${props.TitleClass}`}>{app.translate(props.Title)}</h6>

                    <div className={`card bg-body border-primary-subtle card-primary-subtle text-center ${props.IsTable ? "row mx-2" : ""}`} style={{ "width": props.IsTable ? "50px" : props.Size.length > 0 ? "100px" : "200px", "height": props.IsTable ? "50px" : props.Size.length > 0 ? "100px" : "200px" }}>

                        {!state.showImgCard &&
                            <div id={"image-upload-wrap" + props.Title}>
                                <div className="text-primary-emphasis card-img-overlay d-flex align-items-center  flex-column">
                                    <i className={`iconssc-upload  ${!props.IsTable ? 'fs-1 mt-4 ' : 'fs-4'}`}></i>
                                    {(!props.IsTable && !props.Size.length > 0) &&
                                        <h5 >{app.translate("UploadImage")}</h5>
                                    }
                                </div>

                                <input className="form-control " id={"img-upload-input" + props.Title} type='file'
                                    onChange={readURL} accept="image/*" style={{ "height": props.IsTable ? "50px" : props.Size.length > 0 ? "100px" : "200px", "opacity": "0" }} />
                            </div>}

                        {state.showImgCard &&
                            <div className="card-body p-0">
                                <img className="card-img-top rounded" style={{ "height": props.IsTable ? "50px" : props.Size.length > 0 ? "100px" : "148px" }} src={state.ImageSrc} alt="" id={"file-upload-image" + props.Title} />
                            </div>}

                        {state.showImgCard &&
                            <div className={`${props.Size =="sm"?"position-absolute bg-opacity-50 w-100 fixed-bottom":""}  card-footer ${!props.IsTable ? 'bg-white' : 'col bg-transparent border-0 py-0 px-3 w-auto'}`}>
                                <div className="row">
                                    <button type="button" onClick={removeUpload} className={`btn btn-sm btn-outline-danger rounded-1 mx-1 ${!props.IsTable ? 'col' : 'p-0'}`}><i className="iconssc-trash"></i></button>
                                    <button type="button" onClick={openDialog} className={`btn btn-sm btn-outline-primary rounded-1 mx-1 ${!props.IsTable ? 'col' : 'p-0'}`}><i className="iconssc-eye"></i></button>
                                </div>
                            </div>}
                    </div>
                    <Dialog Show={state.show} Size="md" OnClose={() => setState(old => ({ ...old, show: false }))}>
                        {state.DialogImage}
                    </Dialog>
                </div>
            </div>
        );
    }
}

ImageUpload.propTypes = {
    Model: PropTypes.string,
    OnChange: PropTypes.func.isRequired,
    Title: PropTypes.string,
    IsTable: PropTypes.bool,
    DisplayAsButton: PropTypes.bool,
    Size: PropTypes.string,
    TitleClass: PropTypes.string,
};

ImageUpload.defaultProps = {
    Model: "",
    Title: "",
    IsTable: false,
    DisplayAsButton: false,
    Size: "",
    TitleClass:""
};
