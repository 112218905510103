export const menuData = [
    {
        name: 'Accounting', icon: 'iconssc-accounting', isActive: false, Child:
            [
                { id: 1, name: 'AccountManag' },//ادارة الحسابات
                { id: 2, name: 'AddTeller' },//تعريف امين صندوق
                { id: 12, name: 'FrmExchCommPrice' },//شرائح الاجور
                { id: 3, name: 'DailyOperation' },//العمليات اليومية
                { id: 20, name: 'AgentsOperations' },//عمليات الوكيل
                { id: 4, name: 'DailyReports' },//التقارير اليومية
                { id: 5, name: 'ChecksBanking' },//الشيكات
                { id: 6, name: 'AccQueries' },//الكشوف المحاسبية
                { id: 7, name: 'FinancialStatements' },//القوائم المالية
                { id: 8, name: 'QueriesStatic' },//الاستعلامات الاحصائية
                { id: 9, name: 'PrivateQueries' },//استعلامات خاصة
                { id: 10, name: 'FinalAccCloseFinancialSession' },//الحسابات الختامية واقفال الدورة المالية
                { id: 11, name: 'Analysis' },//تحليل مالي
            ]
    },
    //ادارة الوكلاء
    {
        name: 'AgentSettings', icon: 'iconssc-remittances', isActive: false, Child:
            [
                { id: 50, name: 'DefinitionOf' },//ادارة الوكلاء
                { id: 51, name: 'FrmConfirmAgent' },//ادارة الوكلاء
                { id: 52, name: 'Queries' },//ادارة الوكلاء
                { id: 53, name: 'QueriesStatic' },//ادارة الوكلاء
                { id: 54, name: 'FrmRemitt_PayComm' },//ادارة الوكلاء
                { id: 55, name: 'FrmControlPanelAgent' },//ادارة الوكلاء
            ]
    },
    //ادارة العملاء
    {
        name: 'ClientManag', icon: 'iconssc-remittances', isActive: false, Child:
            [
                { id: 100, name: 'DefinitionOf' },//ادارة الوكلاء
                { id: 101, name: 'FrmConfirmAgent' },//ادارة الوكلاء
                { id: 102, name: 'Queries' },//ادارة الوكلاء
                { id: 103, name: 'FrmControlPanelClient' },//ادارة الوكلاء
            ]
    },
    //الحوالات 
    {
        name: 'Remittances', icon: 'iconssc-remittances', isActive: false, Child:
            [
                { id: 31, name: 'Opretions' },//العمليات
                { id: 32, name: 'QuickRemitt' },//الحوالات السريعة
                { id: 33, name: 'ImportFinances' },//تمويل الاستيراد
                { id: 35, name: 'FrmAddRemttOrderUpdate' },//استعلام حالة الحوالة
                { id: 36, name: 'FrmRemittStatusGeneral' },//استعلام حالة الحوالة عام
                { id: 37, name: 'FollowUpRemitt' },//متابعة الحوالات
                { id: 38, name: 'GeneralQueries' },//استعلامات عامة
                { id: 39, name: 'QueriesStatic' },//استعلامات احصائية
                { id: 40, name: 'HelpWindows' },//نوافذ مساعدة
                { id: 41, name: 'InterRemittStat' },//كشوف الحوالات الداخلي
                { id: 42, name: 'MobileAppManag' },//ادارة تطبيق الجوال
                { id: 43, name: 'TransferPoints' },//نقاط التحويل
            ]
    },
    //الحوالات السريعة 
    {
        name: 'QuickRemitt', icon: 'iconssc-remittances', isActive: false, Child:
            [
                { id: 70, name: 'RemRecApi' },//ارسال حوالة
                { id: 71, name: 'RemPayApi' },//تسديد حوالة
                { id: 72, name: 'FrmAddRemttOrderUpdate' },//استعلام حالة حوالة
                { id: 73, name: 'TransactionSearchApi' },//استعلام الحوالات الصادرة
                { id: 74, name: 'ManagementUsers' },//ادارة المستخدمين
                { id: 75, name: 'ApiErrorsQuery' },//استعلام الاخطاء
            ]
    },
    //الصرافة 
    {
        name: 'Exchange', icon: 'iconssc-remittances', isActive: false, Child:
            [
                { id: 60, name: 'SlicedWages' },//شرائح الاجور
                { id: 61, name: 'ExchOperations' },//العمليات
                { id: 62, name: 'PreciousMetalsOperat' },//عمليات الذهب
                { id: 63, name: 'FrmBillsPayStat' },//استعلام  الفواتير
                { id: 64, name: 'FrmCenterlBankRptExch' },//كشوف المركزي
                { id: 65, name: 'FrmCommState' },//استعلام العمولات
                { id: 66, name: 'FrmExchOperatTeller' },//استعلام عمليات الصناديق
                { id: 67, name: 'FrmExchPurchSales' },//مشتريات و مبيعات
                { id: 68, name: 'Auctions' },//المزادات
            ]
    },
    //الامتثال
    {
        name: 'Compliance', icon: 'iconssc-compliance', isActive: false, Child:
            [
                { id: 90, name: 'ClientCard' },//بطاقة عميل
                { id: 91, name: 'Queries' },//بطاقة عميل
                { id: 92, name: 'Blacklists' },//استعلام عام عملاء
                { id: 93, name: 'FrmCardSetting' },//بطاقة عميل
                { id: 94, name: 'FrmCustCardModifiReq' },//بطاقة عميل
                { id: 95, name: 'FrmSuspiciousTransactions' },//بطاقة عميل
                
            ]
    },//الشحن
    {
        name: 'Tran', icon: 'iconssc-management', isActive: false, Child:
            [
                { id: 110, name: 'SlicedWages' },//
                { id: 112, name: 'FrmTrInvoiceIN' },//
                { id: 113, name: 'Manafest' },//
                { id: 114, name: 'FrmTrInvoiceStore' },//
            ]
    }, //ادوات
    {
        name: 'Tools', icon: 'iconssc-management', isActive: false, Child:
            [
                { id: 80, name: 'ManagementBranches' },//ادارة الفروع
                { id: 81, name: 'FrmUserPassword' },//اعادة ضبط كلمة المرور     
                { id: 82, name: 'ManagementCur' },//ادارة العملات 
                { id: 83, name: 'HelpWindows' },//نوافذ مساعدة  
                { id: 84, name: 'FrmOption' },//خيارات البرنامج  
                { id: 85, name: 'ManagementUsers' },//ادارة المستخدمين  
                { id: 86, name: 'FrmLogFile' },//ملف التسجيل  
                { id: 87, name: 'FrmAudit' },//بطاقة عميل
                { id: 88, name: 'FrmRemExcWarning' },//بطاقة عميل
            ]
    }
]