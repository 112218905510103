import * as http from "./http";
import React from "react";
import store from "../store";
import { hideSpinner, showSpinner } from "../store/spinner";
import { getData } from "../store/data";
import { showMsgDialog } from "../store/msgDialog";

var allCurrencies = [];
var identityKind = [];
var Jobs = [];
var Banks = [];
var tradingSocket = null;

export function ConnectSocket(refreshData, noToken) {


    const token = store.getState().loginState.token;

    var BaseUrl = window.location.origin.replace("http", "ws");
    var socket = new WebSocket(BaseUrl + '/LivePrices?token=' + (noToken ? "" : token));

    socket.onmessage = function (e) {
        var Prices = JSON.parse(e.data).Data || [];
        refreshData(old => ({
            ...old,
            Prices: old.Prices.length == 0 ? Prices : old.Prices.map(oldPrice => {
                let newPrice = Prices.find(r => r.CurrencyCode == oldPrice.CurrencyCode)
                if (newPrice)
                    return { ...newPrice, OldBuy: oldPrice.Buy, OldSell: oldPrice.Sale }
                else
                    return { ...oldPrice, OldBuy: 0, OldSell: 0 }
            })

        }))
        //refreshData(arr)
    };

    socket.onopen = function () {
        console.log("socket Opened");
    };

    socket.onerror = function (error) {
        console.log('WebSocket Error ' + error);
    };

    return () => {

        if (!socket || socket.readyState !== WebSocket.OPEN)
            console.log("socket not connected");
        else {
            console.log("Closing from client");
            socket.close(1000, "Closing from client");
        }
    };
}
export function ConnectTradingSocket(model, refreshData) {

    if (tradingSocket && tradingSocket.readyState === WebSocket.OPEN)
        tradingSocket.close(1000, "Trading Closing from client ");

    const token = store.getState().loginState.token;
    var baseUrl = window.location.origin.replace("http", "ws");
    var url = baseUrl + '/Trading?token=' + (token == null ? "" : token);
    if (model.IsComming != undefined)
        url +=  "&IsComming=" + model.IsComming;
    if (model.FromCurrency != undefined)
        url +=  "&FromCurrency=" + model.FromCurrency;
    if (model.FromCenter != undefined)
        url +=  "&FromCenter=" + model.FromCenter;
    if (model.ToCenter != undefined)
        url +=  "&ToCenter=" + model.ToCenter;
    if (model.AccountID != undefined)
        url +=  "&AccountID=" + model.AccountID;
    if (model.ParentID != undefined)
        url +=  "&ParentID=" + model.ParentID;

    tradingSocket = new WebSocket(url);

    tradingSocket.onmessage = function (e) {
        var data = JSON.parse(e.data).Data || [];
        refreshData(data)
    };

    tradingSocket.onopen = function () {
        console.log("Trading socket Opened");
    };

    tradingSocket.onerror = function (error) {
        console.log('Trading WebSocket Error ' + error);
    };

    return () => {

        if (!tradingSocket || tradingSocket.readyState !== WebSocket.OPEN)
            console.log("Trading socket not connected");
        else {
            console.log("Trading Closing from client");
            tradingSocket.close(1000, "Trading Closing from client ");
        }
    };
}

export function getAreas(cityID, cb) {
    if (!cityID) return;
    http.get("/Service/GetAreas", { cityID },
        function (res) {
            if (cb && typeof cb == "function") cb(res.AdditionalData);
        },
        function (res) {
            if (cb && typeof cb == "function")
                cb(res);
        }
    );
}

export function getDataFromStore(dataName, cb) {
    store.dispatch(
        getData(dataName, {}, (data) => {
            if (cb && typeof (cb) == "function")
                cb(data);
        })
    );
}

export function GetKeyValueArr(strinArr, startIndex) {
    if (!startIndex) startIndex = 0;
    return strinArr.map((r, index) => ({ Key: (index + +startIndex).toString(), Value: translate(r) }));

}
export function ShowLoading() {//Opp_Others 
    store.dispatch(showSpinner());
}
export function getPermission(permID, permKind) {
    let perms = store.getState().data.perms;
    if (!permKind) permKind = "Opp_Read";
    if (options() && options().UserType == 1) return true;
    var Perm = perms.find((item) => +item.PermName == +permID);
    return Perm ? Perm[permKind] : false;
}
export function options() {
    return store.getState().data.options;
}
export function dataURItoBlob(dataURI) {
    var str = dataURI.toString();
    var binary = atob(str.split(",")[1]);
    var mimeString = str.split(",")[0].split(":")[1].split(";")[0];
    var array = [];
    for (var i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], {
        type: mimeString,
    });
}
export function arrayBufferToBase64(buffer) {
    if (buffer == null || buffer.length < 2) return "";
    let binary = "";
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return "data:image/png;base64, " + window.btoa(binary);
}
export function print(url, body) {
    body = body === undefined ? {} : body;
    http.post(url, body, (res) => {
        if (!res.Success) {
            disblayError(res);
        } else {
            const byteArray = Uint8Array.from(atob(res.Message).split('').map(char => char.charCodeAt(0)));
            const blobUrl = URL.createObjectURL(new Blob([byteArray], { type: 'application/pdf' }));
            window.open(blobUrl, '_blank')            
            //try {
            //    var qWindow = window.open("");
            //    qWindow.document.write(
            //        "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
            //        encodeURI(res.Message) +
            //        "'></iframe>"
            //    );
            //} catch (e) {
            //    var a = document.createElement("a");
            //    a.href = "data:application/pdf;base64, " + encodeURI(res.Message);
            //    a.download = "Print.pdf";
            //    a.click();
            //}
        }
    });
}
export function excel(url, body, fileName) {
    body = body === undefined ? {} : body;
    if (!fileName || fileName == "") fileName = "EXCEL";
    http.post(url, body, (res) => {
        if (!res.Success) {
            disblayError(res);
        } else {
            var newPage = document.createElement("a");
            newPage.href =
                "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
                res.Message;
            newPage.download = translate(fileName) + ".xlsx";
            newPage.click();
        }
    });
}
export function HideLoading() {
    store.dispatch(hideSpinner());
}

export function PreparMessage(Message, separator) {
    if (!separator) separator = "\n";
    return Message.split(separator).map((r, index) => <div key={index}> {"-" + r} <br /></div>)
}

export function showAlert(Body, Title, OnClose, Ok, CloseLabel, ButtonsArr) {
    store.dispatch(showMsgDialog(Body, Title, OnClose, Ok, CloseLabel, ButtonsArr));
}

export function moveConfirm(Body, Title, Ok, OnClose, CloseLabel) {
    if (!Title || Title === "") Title = "MsgContinue";
    if (!Body || Body === "") Body = translate("MsgContinue");
    store.dispatch(showMsgDialog(Body, Title, OnClose, Ok, CloseLabel));
}

export function moveReason(ConfirmMsg, Label, Title, Ok, OnClose, CloseLabel, ExtraButtons,DefValue) {
    if (!Title || Title === "") Title = "MsgContinue";
    store.dispatch(showMsgDialog("", Title, OnClose, Ok, CloseLabel, ExtraButtons, ConfirmMsg, Label, DefValue));
}

export function translate(key) {
    let TranslationKeys = store.getState().data.LangKey;
    if (key == undefined || typeof key != "string") return "";
    if (TranslationKeys == undefined) return key;
    for (var i = 0; i < TranslationKeys.length; i++) {
        if (key.toLowerCase() == TranslationKeys[i].Key.toLowerCase())
            return TranslationKeys[i].Value.replace(/&#252;/g, "ü")
                .replace(/&#231;/g, "ç")
                .replace(/&#246;/g, "ö")
                .replace(/&#199;/g, "Ç")
                .replace(/&#220;/g, "Ü")
                .replace(/&#214;/g, "Ö")
                .replace(/&#214;/g, "ş");
        //"i": "İ", "ş": "Ş", "ğ": "Ğ", "ü": "Ü", "ö": "Ö", "ç": "Ç", "ı": "I"
    }
    return key;
}
export function getNumberWriting(number, currencyID, cb) {
    number = number + "";
    number = number.replace(/,/g, "");
    if (currencyID == undefined) currencyID = 0;
    if (number == undefined || number == null || parseFloat(number)===0)
        if (cb && typeof cb == "function") 
            cb("");

    http.get("/Service/GetNumberWriting", {
        Number: parseFloat(number), CurrencyID: currencyID
    }, (res) => {
        if (cb && typeof cb == "function") {
            cb(res.AdditionalData);
        }
    }, true);
}
export function disblayError(res, IsWarning,cb) {
    let validationMessage = "";
    if (res.ValidationResults && res.ValidationResults.length > 0) {
        validationMessage = (
            <div>
                {res.ValidationResults.map((row) => {
                    return <div>{row.Key + "code #" + row.Value}</div>;
                })}
            </div>
        );
    } else if (res.Message) {
        validationMessage = translate(res.Message) + (res.Code ? " , code " + res.Code : "");
    } else validationMessage = translate("Error_Operation");
    showAlert(validationMessage, IsWarning ? "Warning" : "Validation", () => { if (cb && typeof cb == "function") cb(res)});
}
export function disblayErrorOrWarnings(res, model, save,errorCb) {
    if (res.ValidationResults.length > 0 || !res.CarryOnData || res.CarryOnData.length == 0) {
        disblayError(res, false, errorCb);
    }
    else {
        var Msg = "";
        for (var i = 0; i < res.CarryOnData.length; i++) {
            if (!res.CarryOnData[i].Approv) {
                Msg += res.CarryOnData[i].Msg + " ";
            }
        }
        showAlert(Msg, "Warning", function () { },
            function () {
                model.WarningList = [];
                for (var i = 0; i < res.CarryOnData.length; i++) {
                    model.WarningList.push({ WarningExeption: res.CarryOnData[i].WarningExeption, Approv: true });
                }
                save();
            });
    }
}
export function roundNumber(Number, Currency, returnString = false) {
    try {
        Number = +((Number + "").replace(/,/g, ''));
        if (Number == NaN) return 0;
        var NumbersAfterDecimal = options().NumbersAfterDecimal;
        var RoundType = options().RoundType;
        var RoundTo = 2;
        var CurrList = options().CurrList;
        if (+Currency != 0) {
            for (var i = 0; i < CurrList.length; i++) {
                if (+CurrList[i].Key === +Currency) {
                    RoundTo = +CurrList[i].Value;
                    break;
                }
            }
        }
        var value = +Number.toLocaleString(
            "en-US", { useGrouping: false, maximumFractionDigits: RoundTo }
        );
        value = value.toLocaleString("en-US", {
            useGrouping: false,
            minimumFractionDigits: NumbersAfterDecimal,
        });
        var last = value.substr(value.length - 1);
        if (
            RoundTo > 0 &&
            RoundType > 0 &&
            NumbersAfterDecimal > 0 &&
            value % 1 > 0
        ) {
            value = value + "";
            switch (RoundType) {
                case 1:
                    switch (last) {
                        case "1":
                        case "2":
                            value = value.substr(0, value.length - 1) + "0";
                            break;
                        case "3":
                        case "4":
                        case "6":
                        case "7":
                            value = value.substr(0, value.length - 1) + "5";
                            break;
                        case "8":
                            value =
                                (+value * Math.pow(10, RoundTo) + 2) / Math.pow(10, RoundTo);
                            break;
                        case "9":
                            value =
                                (+value * Math.pow(10, RoundTo) + 1) / Math.pow(10, RoundTo);
                            break;
                        default:
                            break;
                    }
                    break;
                case 2:
                    value =
                        +last <= 5
                            ? (value + "").substr(0, (value + "").length - 1) + "0"
                            : (+value * Math.pow(10, RoundTo) + (10 - +last)) /
                            Math.pow(10, RoundTo);
                    break;
                case 3:
                    var last25 = value.substr(value.length - 2) % 25;
                    value =
                        last25 <= 12
                            ? (+value * Math.pow(10, RoundTo) - last25) /
                            Math.pow(10, RoundTo)
                            : (+value * Math.pow(10, RoundTo) + (25 - +last25)) /
                            Math.pow(10, RoundTo);
                    break;
                case 4:
                    var last50 = value.substr(value.length - 2) % 50;
                    value =
                        last50 <= 25
                            ? (+value * Math.pow(10, RoundTo) - last50) /
                            Math.pow(10, RoundTo)
                            : (+value * Math.pow(10, RoundTo) + (50 - +last50)) /
                            Math.pow(10, RoundTo);
                    break;
                default:
                    break;
            }
        }
        value = (+value).toLocaleString("en-US", {
            useGrouping: returnString,
            minimumFractionDigits: NumbersAfterDecimal,
        });
        if (+Number != 0 && +value == 0) return Number;
        return value;
    } catch (e) {
        return Number;
    }
}
export function getAccounts(cb, CurrencyID = 0, AccountsKind = "", Level = 5) {
    if (CurrencyID == 0 && Level == 5) {
        cb([]);
        return;
    }
    http.get(
        "/Operation/GetAccounts",
        { CurrencyID, AccountsKind, Level },
        function (res) {
            if (cb && typeof cb == "function") cb(res.AdditionalData);
        },
        function (res) {
            if (cb && typeof cb == "function") {
                cb(res);
            }
        }
    );
}
export function getAccountBalance(accountID, cb) {
    if (+accountID == 0) {
        cb(0);
        return;
    }
    http.get(
        "/Service/GetAccountBalance",
        { AccountID: accountID },
        function (res) {
            if (cb && typeof cb == "function") cb(res.AdditionalData);
        },
        function (res) {
            if (cb && typeof cb == "function") {
                cb(0);
            }
        }
    );
}


export function GetCardRequirement(cb) {
    http.get("/Service/GetCardRequirement", {},
        function (res) {
            var CardRequirement = {};
            res.AdditionalData.map(r => {
                CardRequirement[r.FieldName] = { Required: r.Required, Show: r.Show }
            })
            cb(CardRequirement);
        },
        function (res) {
            if (cb && typeof cb == "function") {
                cb(res);
            }
        }
    );
}

export function haveBulletinToday(MoveDate, cb) {
    http.get(
        "/Service/NoBulletinToday",
        { MoveDate: MoveDate },
        function (res) {
            if (!res.AdditionalData) cb(res);
            else
                moveConfirm(translate("MsgNoCurrThisDate"), "", function () {
                    if (cb && typeof cb == "function") {
                        cb(res);
                    }
                });
        },
        function (res) {
            if (cb && typeof cb == "function") {
                cb(res);
            }
        }
    );
}

export function getTellerCurrenciesList(cb, tellerId) {
    if (tellerId == 0) {
        cb([]);
        return;
    }
    http.get(
        "/Operation/GetTellerCurrenciesList",
        { tellerId },
        function (res) {
            if (cb && typeof cb == "function") cb(res.AdditionalData);
        },
        function (res) {
            if (cb && typeof cb == "function") {
                cb(res);
            }
        }
    );
}

export function getTellerAccounts(tellerId, currencyId, BoxesKind, cb) {

    http.get(
        "/Operation/GetBoxes",
        { tellerId, currencyId, BoxesKind },
        function (res) {
            if (cb && typeof cb == "function") cb(res.AdditionalData);
        },
        function (res) {
            if (cb && typeof cb == "function") {
                cb(res);
            }
        }
    );
}
export function getBankAccounts(branchID, currencyId, cb) {
    if (currencyId == 0) return
    http.get("/Operation/GetBankAccounts", { branchID, currencyId }, function (res) {
        if (cb && typeof cb == "function") cb(res.AdditionalData);
    }, function (res) {
        if (cb && typeof cb == "function")
            cb(res);
    });
}
export function GetCommTypeValue(slide, cb,url) {
    if (slide.Currency_ID == 0 || slide.Amount == 0) {
        cb({ WageType: 0, Value: 0, CostVal :0});
        return;
    }
    http.post(url ? url:"/Service/GetCommTypeValue", slide, (res) => {
        if (cb && typeof cb == "function") cb(res.AdditionalData);
    });
}

export function getAllCurrencies(cb) {
    if (allCurrencies.length == 0) {
        http.get(
            "/Service/GetAllCurrencies",
            {},
            function (res) {
                allCurrencies = res.AdditionalData;
                if (cb && typeof cb == "function") cb(res.AdditionalData);
            },
            function (res) {
                if (cb && typeof cb == "function") {
                    cb(res);
                }
            }
        );
    } else if (cb && typeof cb == "function") cb(allCurrencies);
}

export function getBanks(cb) {
    if (Banks.length == 0) {
        http.get("/Service/GetBanks", {},
            function (res) {
                Banks = res.AdditionalData;
                if (cb && typeof cb == "function") cb(res.AdditionalData);
            },
            function (res) {
                if (cb && typeof cb == "function") {
                    cb(res);
                }
            }
        );
    } else if (cb && typeof cb == "function") cb(Banks);
}

export function getIdentityKind(cb) {
    if (identityKind.length == 0) {
        http.get(
            "/Service/GetIdentityKind",
            {},
            function (res) {
                identityKind = res.AdditionalData;
                if (cb && typeof cb == "function") cb(res.AdditionalData);
            },
            function (res) {
                if (cb && typeof cb == "function") {
                    cb(res);
                }
            }
        );
    } else if (cb && typeof cb == "function") cb(identityKind);
}

export function getJobs(cb) {
    if (Jobs.length == 0) {
        http.get(
            "/Service/GetJobs",
            {},
            function (res) {
                Jobs = res.AdditionalData;
                if (cb && typeof cb == "function") cb(res.AdditionalData);
            },
            function (res) {
                if (cb && typeof cb == "function") {
                    cb(res);
                }
            }
        );
    } else if (cb && typeof cb == "function") cb(Jobs);
}

export function setCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

export function getCookie(name, defValue) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return defValue;
}

export function eraseCookie(name) {
    document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

export function dateToString(date) {
    return (
        date.getFullYear() +
        "-" +
        (date.getMonth() < 9 ? "0" : "") +
        (date.getMonth() + 1) +
        "-" +
        (date.getDate() < 10 ? "0" : "") +
        date.getDate()
    );
}

export function today(number) {
    if (!number) number = 0;
    let date = new Date(new Date().getTime() + number * 24 * 60 * 60 * 1000);
    return dateToString(date)

}

export function now(number) {
    if (!number) number = 0;
    let date = new Date(new Date().getTime() + number * 60 * 60 * 1000);
    return (date.getHours() < 10 ? "0" : "") + date.getHours() + ":" +
        (date.getMinutes() < 9 ? "0" : "")+ (date.getMinutes() + 1)
    
}

export function getBranchTellers(branchId, cb) {
    http.get(
        "/Service/GetBranchTellers",
        { branchId },
        function (res) {
            if (cb && typeof cb == "function") cb(res.AdditionalData);
        },
        function (res) {
            if (cb && typeof cb == "function") {
                cb(res);
            }
        }
    );
}
export function getAllBranchTeller(branchId, cb) {
    http.get(
        "/Service/GetAllBranchTeller",
        { branchId },
        function (res) {
            if (cb && typeof cb == "function") cb(res.AdditionalData);
        },
        function (res) {
            if (cb && typeof cb == "function") {
                cb(res);
            }
        }
    );
}
export function findObjectByKey(array, key, value) {
    if (array && array != null)
        for (var i = 0; i < array.length; i++) {
            if (array[i][key] + '' === value + '') {
                return array[i];
            }
        }
    return null;
}

export function SaveFrozenReason(accountID, reason, cb) {
    http.get("/AccManagement/BlockAccount", { AccountIDL4: accountID, BlockReason: reason }, (res) => {
        if (cb && typeof cb == "function")
            cb(res)
    })
}

export function OpenOrigin(Kind, ID) {
    if (Kind == 0 || ID == 0) return;
    var baseUrl = http.BaseUrl();
    switch (+Kind) {
        case 1:
            window.open("/AccOperations/AccountEntry/" + ID);
            break;
        case 3:
            window.open(baseUrl + "AccOperations/CashOperations/Receive/" + ID);
            break;
        case 4:
            window.open(baseUrl + "/AccOperations/CashOperations/Payment/" + ID);
            break;
        case 10:
            window.open(baseUrl + "/AccOperations/CashOperations/Deposit/" + ID);
            break;
        case 11:
            window.open(baseUrl + "/AccOperations/CashOperations/Withdraw/" + ID);
            break;
        case 13:
            window.open(baseUrl + "/AccOperations/CashOperations/CashOrder/" + ID);
            break;
        case 5:
            window.open(baseUrl + "/AccOperations/Adjustment/" + ID);
            break;
        case 32:
            window.open(baseUrl + "/Exch/ExchBuy/" + ID);
            break;
        case 33:
            window.open(baseUrl + "/Exch/ExchSale/" + ID);
            break;
        case 37:
        case 38:
            window.open(baseUrl + "/Exchange/MetalOperations/" + Kind + "/" + ID);
            break;
        case 51:
            window.open(baseUrl + "/RemOperations/RemittRec/" + ID);
            break;
        case 52:
            window.open(baseUrl + "/RemOperations/RemittIn/" + ID);
            break;
        case 55:
        case 56:
        case 58:
        case 59:
        case 60:
            http.get("/Remittance/IsInstant", {
                    ID: ID,
                    Kind: Kind,
            }, res => {
                if (res.Code == '58')
                    window.open(baseUrl + "/RemOperations/RecInstant/" + res.Message);
                else if (res.Code == '59')//
                    window.open(baseUrl + "/RemOperations/InInstant/" + res.Message);
                else if (res.Code == '60')
                    window.open(baseUrl + "/RemOperations/InOutInstant/" + res.Message);
                else if (res.Code == '52')
                    window.open(baseUrl + "/RemOperations/RemittIn/" + ID);
                else if (res.Code == '51')
                    window.open(baseUrl + "/RemOperations/RemittRec/" + ID);
                else if (res.Code == '55')
                    window.open(baseUrl + "/RemOperations/RemittPay/" + ID);
                else if (res.Code == '56')
                    window.open(baseUrl + "/RemOperations/RemittOut/" + ID);
            });
            break;
        case 24:
            window.open(baseUrl + "/Adjustment/CreditTransfer/" + ID);
            break;
        case 25:
            window.open(baseUrl + "/Adjustment/BetweenCurrencies/" + ID);
            break;
        case 26:
            window.open(baseUrl + "/Adjustment/TransferInProgressUser/" + ID);
            break;
        case 99:
            window.open(baseUrl + "/ExTrOperation/MatInvoice/0/" + ID);
            break;
        case 57:
            window.open(baseUrl + "/RemOperations/RemByAcc/" + ID);
            break;
        case 65:
            window.open(baseUrl + "/Exch/AuctionSale/" + ID);
            break;
        case 67:
            window.open(baseUrl + "/Exch/AuctionDeliver/" + ID);
            break;
        default:
    }
}

export function GetPriceRange(Cur1, Cur2, type, Kind, AccID, cb) {
    if (AccID == undefined) AccID = 0;

    if (Cur1 == Cur2) {
        if (cb && typeof (cb) == "function") {
            cb({ Min_Value: 1, Max_Value: 1 });
        }
        return;
    }
    http.get("/Service/GetPriceRange", {
        Cur1: Cur1,
        Cur2: Cur2,
        type: type,
        Kind: Kind,
        AccID: AccID
    }, (res) => {
        if (cb && typeof (cb) == "function") {
            cb(res.AdditionalData);
        }
    })
};

export function GetPrice(currency1Id, currency2Id, way, PriceKind, cb, AgentAccCurrency=0, Amount = 0, PriceDate = null) {
    if (currency1Id == currency2Id) {
        if (cb && typeof (cb) == "function") {
            cb({ price: 1, type: 0 });
        }
        return;
    }
    var obj = {
        currency1Id,
        currency2Id,
        type: way,
        PriceKind,
        AgentAccCurrency,
        Amount
    }
    if (PriceDate != null)
        obj["PriceDate"] = PriceDate;
    http.get("/Service/GetCurrencyPrice", obj, (res) => {
        if (cb && typeof (cb) == "function") {
            cb(res.AdditionalData);
        }
    })
};

export function GetAccountPrice(accountId, cur1, cur2, way, kind, cb, Amount=0) {
    if (cur1 == cur2 || cur1 == 0 || cur2==0) {
        if (cb && typeof (cb) == "function") {
            cb({ price: 1, type: 0 });
        }
        return;
    }
    http.get("/Service/GetAccountCurrencyPrice", {
        AccountId: accountId,
        currency1Id: cur1,
        currency2Id: cur2,
        type: way,
        PriceKind: kind,
        Amount: Amount ? Amount : 0
    }, (res) => {
        if (cb && typeof (cb) == "function") {
            cb(res.AdditionalData);
        }
    })
};

export function GetAgentCurrencyPrice(AgentID, currency1Id, currency2Id, type, PriceKind, cb, Amount=0) {
    if (currency1Id == currency2Id || currency1Id == 0 || currency2Id == 0) {
        if (cb && typeof (cb) == "function") {
            cb({ price: 1, type: 0 });
        }
        return;
    }
    http.get("/Service/GetAgentCurrencyPrice", {
        AgentID,
        currency1Id,
        currency2Id,
        type,
        PriceKind,
        Amount
    }, (res) => {
        if (cb && typeof (cb) == "function") {
            cb(res.AdditionalData);
        }
    })
};

export function RefreshAgentBalance() {
    store.dispatch(getData("AgentBalance"));
}
