import React from "react";
import PropTypes from "prop-types";
import * as app from "../services/AppService";
import Button from "../components/Button";

function Select(props) {

    function handler(event) {
        const { value } = event.target;
        if (props.OnChange && typeof props.OnChange == "function")
            if (props.selectdObject) {
                var selected = props.Options.find(r => r[props.Key] == value);
                props.OnChange(selected);
            }
            else
                props.OnChange(value, props.Options, props.ID);
    }

    let select = (<select
        className={`form-select form-select-sm rounded ${props.Required && !props.Model ? " is-invalid  border-1" : " border border-0 " + props.Class}`}
        disabled={props.Disabled}
        required={props.Required && !props.Model}
        onChange={handler}
        onBlur={props.OnBlur}
        id={props.ID ? props.ID : "slct" + props.Label}
        value={props.Model}>

        {props.Options && props.Options.find((item) => +item[props.Key] === 0 || item[props.Key] + '' !== props.Model + '') === undefined && (
            <option defaultValue disabled="disabled" value="0"></option>
        )}
        {props.Options && props.Options.find((item) => item[props.Key] + '' === props.Model + '') === undefined && (
            <option defaultValue disabled="disabled" value={props.Model}></option>
        )}

        {props.Options.map((item) => {
            return (
                <option key={item[props.Key]} value={item[props.Key]}>
                    {typeof (props.Value) == "function" ? props.Value(item) : item[props.Value]}
                </option>
            );
        })}
    </select>)
    return (props.IsTable ? select :
        <div className={props.containerClass}>
                        {props.Label && (
                <label className={props.LabelClass}>{app.translate(props.Label)}</label>
            )}
            <div className={`border rounded bg-light-subtle input-group `} >
                {select }
                {props.Icon &&
                    //<button className="btn btn-primary  p-0 px-2" type="button" onClick="">
                    //    <i className={` text-white  iconssc-${props.Icon}`} />
                    //</button>
                    <Button
                    Icon={props.Icon}                                                
                    Class="text-primary rounded-end rounded-1  "
                    Clicked={props.Clicked} />
                }
            </div>
        </div>

    );
}

Select.propTypes = {
    Label: PropTypes.string,
    LabelClass: PropTypes.string,
    Options: PropTypes.array.isRequired,
    Class: PropTypes.string,
    Disabled: PropTypes.bool,
    Required: PropTypes.bool,
    Key: PropTypes.string,
    ID: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    Value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.func
    ]),
    Model: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
    ]).isRequired,
    OnChange: PropTypes.func,
    containerClass: PropTypes.string,
    Clicked: PropTypes.func,
    IsTable: PropTypes.bool,
};

Select.defaultProps = {
    Class: " ",
    Key: "Key",
    Value: "Value",
    LabelClass: "fw-semibold text-primary-emphasis ",
    Disabled: false,
    Required: false,
    containerClass: " mt-1",
    IsTable: false,
};

export default Select;
