import React, { ReactNode, ReactElement } from 'react'
import * as app from "../services/AppService.js";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import TooltipElm from 'react-bootstrap/Tooltip';
import { BasicProps } from "./BasicProps"

enum ButtonTypes {
    "button",
    "submit",
    "reset",
    undefined
}

interface ButtonProps extends BasicProps {
    Key: string, Type?: "submit" | "reset" | "button" | undefined, Clicked: Function, Rounded: string,
    IsTable: boolean, Icon: string, ID: string, children: ReactElement, IconSize: string, hide: boolean, Tooltip: string
}

function Button({ Disabled, Class, Label, Key, Type, Clicked, Rounded, IsTable,  Icon,  ID,  children, IconSize, hide, Tooltip }: ButtonProps): ReactNode {    

    function clicked(e: any) {
        if (Clicked) Clicked(Key)
        e.currentTarget.blur(); //الخروج من الزر بعد الضغط عليه
    }

    let btn = (<button type={Type} className={`btn btn-sm fw-normal focus-ring rounded-${Rounded} 
         ${(IsTable ? "btn-primary " : Class)} 
         ${(Icon !== "" && Rounded !== "circle" ? "  px-2 " : " ")}`}
        onClick={clicked}
        id={ID ? ID : "btn" + Label}
        data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Tooltip on top" disabled={Disabled} >
         <span className={(Icon !== "" ? "" : " ")}>{app.translate(Label)}</span>

         {children}
        {Icon && <span className={`fs-${IconSize} iconssc-${Icon + (Label !== "" ? " ps-3" : " ")}`}> </span>}
     </button>);
    return (hide ? "" :
         !Tooltip ? btn :
             <OverlayTrigger key={Key + 'bottom'} placement='bottom'
                 overlay={
                     <TooltipElm style={{ position: "fixed" }}  id={`tooltip-bottom`}>
                         {app.translate(Tooltip)}
                     </TooltipElm>
                 }>
                 {btn}
             </OverlayTrigger>
    );
}

Button.defaultProps = {
    Class: "btn-primary shadow-sm ",
    Disabled:false,
    Label: "",
    Icon: "",
    IconSize: "6",
    Rounded: "1",
    Tooltip: "",
    Type: "button"
}

export default Button;