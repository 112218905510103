import React, { useEffect ,useState} from "react";
import PropTypes from "prop-types";
import * as app from "../services/AppService";

function CurrencyInput(props) {

    const [model, setModel] = useState({
        elmntVal: 0,
        isfocused: false
    })

    useEffect(() => {
        if (!model.isfocused)
            setModel(old => ({ ...old, elmntVal: old.isfocused ? format(props.Model) : app.roundNumber(props.Model, props.CurrencyID, true) }))
    }, [props.Model, props.CurrencyID, model.isfocused]);


    function handler(event) {
        const { value } = event.target;
        if (props.OnChange && typeof (props.OnChange) == "function") {
            props.OnChange((value + '').replace(/,/g, ''));
        }
        setModel(old => ({
            ...old,
            elmntVal: format(value)
        }));
    }

    function format(value) {
        var arr = (value + "").split('.')
        return arr && arr.length > 1 && arr[1] == '' ?
            (+(arr[0].replace(/,/g, ''))).toLocaleString('en-US') + "."
            : (+(arr[0].replace(/,/g, ''))).toLocaleString('en-US') + (arr.length < 2 ? "" : "." + arr[1])
    }

    function hanldeKeydown(evt) {
        if (![96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 110, 8, 39, 37, 46, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 9, 65, 190].includes(evt.keyCode))
            evt.preventDefault()
    }

    function onBlur() {
        setModel({ isfocused: false, elmntVal: app.roundNumber(model.elmntVal, props.CurrencyID, false) });
        if (props.OnBlur && typeof (props.OnBlur) == "function")
            props.OnBlur();
    }

    const handleFocus = (event) => {
        event.target.select();
        setModel(old => ({ ...old, isfocused: true }))
    }
    
    let input = (<input type="text"
        id={props.ID ? props.ID : "cur" + props.Label}
        key={props.Key}
        className={`form-control form-control-sm border rounded ${props.IsTable ? " border-1  text-center " + props.TableClass : props.Class}`}
        disabled={props.Disabled}
        onChange={handler}
        onKeyDown={hanldeKeydown}
        value={model.elmntVal}
        onFocus={handleFocus}
        onBlur={onBlur}
    />)

    return (props.IsTable ? input :
        <div className="mt-1">
            <label className={`fw-bold  ${props.LabelClass}`}>{app.translate(props.Label)}</label>
            <div className={`input-group rounded  ${props.ContainerClass}`}>
                {input}
                {props.Icon &&
                    <button className="btn btn-light" type="button" disabled>
                        <i className={"text-primary-emphasis " + `icofont-${props.Icon}`} />
                    </button>
                }
            </div>
        </div>
    );
}

CurrencyInput.propTypes = {
    Label: PropTypes.string,
    Class: PropTypes.string,
    Disabled: PropTypes.bool,
    Model: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired,
    OnChange: PropTypes.func,
    Key: PropTypes.string,
    IsTable: PropTypes.bool,
    OnBlur: PropTypes.func, 
    CurrencyID: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
}
CurrencyInput.defaultProps = {
    Class: " ",
    LabelClass: "text-primary-emphasis",
    ContainerClass: " ",
    Disabled: false,
    Key: "input",
    IsTable: false,
}
export default CurrencyInput;