import React from "react";
import * as app from "../services/AppService";
import PropTypes from "prop-types";

export default function RadioBox(props) {
    function handler(event) {
        const { checked } = event.target;
        if (props.OnChange && typeof props.OnChange == "function")
            props.OnChange(checked);
    }
    return (
        <>
            {app.translate(props.Address) && <p className="text-primary-emphasis fw-semibold m-1">{app.translate(props.Address)}</p>}

            <div className={props.ContainerClass}>
                <input
                    id={props.ID ? props.ID : "radio" + props.Label}
                    type="radio"
                    name={props.Name}
                    disabled={props.Disabled}
                    className="form-check-input ms-1"
                    onChange={handler}
                    value={props.Value}
                    checked={props.Value}
                />
                <label htmlFor={props.Name} className={"ms-2 " + props.Class}>
                    {app.translate(props.Label)}
                </label>
            </div>
        </>
    );
}

RadioBox.propTypes = {
    Address: PropTypes.string,
    OnChange: PropTypes.func.isRequired,
    Name: PropTypes.string,
    Disabled: PropTypes.bool,
    Class: PropTypes.string,
    Label: PropTypes.string,
};
RadioBox.defaultProps = {
    Class: "fw-semibold text-primary-emphasis ",
    ContainerClass: "",
    Name: "chk",
    Label: "",
    Disabled: false,
};