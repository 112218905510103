import React from 'react';
import Pagination from 'react-bootstrap/Pagination';
import Select from '../Select.js';
import PropTypes from "prop-types";
import * as app from "../../services/AppService"

export default function TablePagination(props) {

    return (
        <div className="row g-0 my-2">
            <div className="col col-lg-3">
                {+props.Count > props.model.pageSize && 
                    <nav aria-label="Page navigation">
                        <ul className="pagination pagination-sm m-0">
                            <li className="page-item"><span className="page-link" onClick={() => props.PageIndexHandle((props.model.pageIndex - 1))}>{app.translate("Previous")}</span></li>

                            {(props.model.pageIndex - 2) > 0 && <li className="page-item" onClick={() => { props.PageIndexHandle((props.model.pageIndex - 2)) }}>
                                <span className="page-link "> {(props.model.pageIndex - 2)}</span> </li>}
                            {(props.model.pageIndex - 1) > 0 && <li className="page-item" onClick={() => { props.PageIndexHandle((props.model.pageIndex - 1)) }}>
                                <span className="page-link "> {(props.model.pageIndex - 1)}</span> </li>}

                            <li className="page-item"><span className="page-link active">{props.model.pageIndex}</span></li>

                            {(props.model.pageIndex + 1) <= props.model.totalPages &&
                                <li className="page-item" onClick={() => { props.PageIndexHandle((props.model.pageIndex + 1)) }}>
                                    <span className="page-link "> {(props.model.pageIndex + 1)}</span> </li>}

                            {(props.model.pageIndex + 2) <= props.model.totalPages &&
                                <li className="page-item" onClick={() => { props.PageIndexHandle((props.model.pageIndex + 2)) }}>
                                    <span className="page-link "> {(props.model.pageIndex + 2)}</span> </li>}

                            <li className="page-item"><span className="page-link" onClick={() => props.PageIndexHandle((props.model.pageIndex + 1))}>{app.translate("Next")}</span ></li>
                        </ul>
                    </nav>}

            </div>
            {!props.HideTotalCount &&props.rowCountElement }
            <div className={`col-auto d-none d-sm-block ${!props.HideTotalCount ? null : " ms-auto "} `} >
                <Select Name="pageSize" containerClass="mt-0"
                    Options={props.model.possiblePageSizes}
                    Model={props.model.pageSize} OnChange={props.PageSizeHandle} />
            </div>
        </div>
    );
}



TablePagination.propTypes = {
    model: PropTypes.object.isRequired,
    PageSizeHandle: PropTypes.func.isRequired,
    HideTotalCount:PropTypes.bool.isRequired,
    Count:PropTypes.string.isRequired
}

