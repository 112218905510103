import React, { Component } from "react";
import * as app from "../services/AppService";
import PropTypes from "prop-types";

export default function checkbox(props) {
    function handler(event) {
        const { checked } = event.target;
        if (props.OnChange && typeof props.OnChange == "function")
            props.OnChange(checked, props.Key);
    }
    let label = (<label htmlFor={props.Name} className={"ms-2 form-check-label text-primary-emphasis " + props.Class}>
        {app.translate(props.Label)}
    </label>);
    let input = (<input
        type="checkbox"
        name={props.Name}
        disabled={props.Disabled}
        className="form-check-input"
        onChange={handler}
        checked={props.Model}
    />)
    let IsAr = app.getCookie("language", "en") == "ar";
    return (
        <div className={props.IsTable?"":`form-check ${props.checkClass}`}>
            {IsAr ? input : label}
            {IsAr ? label : input}
        </div>
    );
}

checkbox.propTypes = {
    OnChange: PropTypes.func,
    Name: PropTypes.string,
    Disabled: PropTypes.bool,
    Class: PropTypes.string,
    checkClass: PropTypes.string,
    Label: PropTypes.string,
    Model: PropTypes.bool,
    Clicked: PropTypes.func,
    IsTable: PropTypes.bool,
    Key: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
};
checkbox.defaultProps = {
    Class: "",
    checkClass: "mt-1",
    Name: "chk",
    Label: "",
    IsTable: false,
    Disabled: false,
};
